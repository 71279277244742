import styled from 'styled-components'

export const BackButtonContainer = styled.button`
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 7px;
  }
`
