import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  background: #ffffff;
  z-index: 100;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
  grid-column: 2 / span 9;
`

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

export const Title = styled.h4`
  max-width: calc(100vw - 28px - 24px - 72px - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;

  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.0025em;
`
