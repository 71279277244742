import styled from 'styled-components'

export const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div:nth-child(1) {
    background-color: #ffffff;
    margin: 0;
    padding: 19px 24px 24px 24px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-left: 24px;
      display: none;
    }
  }
`

export const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(0, 80px)) 1fr;
  gap: 24px;
  padding-top: 24px;
  min-height: calc(100vh - 118px - 72px);

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding-top: 0;
  }
`

export const MainMain = styled.main`
  background: white;
  border-radius: 16px;
  grid-column: 2 / span 9;
  height: fit-content;
  min-height: 496px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    border-radius: 0;
    min-height: 100vh;
    padding: 0;
  }
`
