import styled from 'styled-components'
import { Overline } from '~/styles/global'

export const DataProviContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DescriptionText = styled(Overline)`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-transform: none;
`
