import NextHead from 'next/head'

interface IHead {
  title: string
}

export const MainHead = ({ title }: IHead) => (
  <NextHead>
    <title>{title} | Plataforma do avalista</title>
  </NextHead>
)
