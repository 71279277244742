import { useCallback, useContext, useMemo } from 'react'
import { TemplateContext } from '~/contexts/TemplateContext'
import { IStep } from '~/@types/IStep'

interface IInfosFromStep {
  title: string
  showProgressBar: number
  previousScreenUniqueName: IStep
  arrowDisabled?: boolean
}

export const useProgressBar = () => {
  const { progressBar, step } = useContext(TemplateContext)

  const title = useMemo(
    () => ({
      financingSummary: 'Confirmação',
      basicInfo: 'Cadastro',
      address: 'Cadastro',
      emailVerification: 'Verificação',
      checkoutEmailVerification: 'Verificação',
      phoneVerification: 'Verificação',
      preSelfie: 'Documentos',
      contractSummary: 'Contrato',
      preDocument: 'Documentos',
    }),
    [],
  )

  const getInfosFromStep = useCallback((): IInfosFromStep => {
    const defaultProgressData = {
      showProgressBar: progressBar?.percentageCompleted || 10,
      previousScreenUniqueName: progressBar?.previousScreenUniqueName || 'financingSummary',
      arrowDisabled: !progressBar?.canBack,
    }

    return {
      title: title[step as keyof typeof title],
      ...defaultProgressData,
    }
  }, [step, progressBar, title])

  return { getInfosFromStep, title }
}
