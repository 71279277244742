export const isPhoneValid = (information: string) => {
  if (!information) return false

  const phoneString = String(information)

  const phone = phoneString.replace(/\D/g, '')

  if (phone?.length === 11) {
    return true
  }

  return false
}
