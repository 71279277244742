import { useTheme } from 'styled-components'

export type colorType = 'success' | 'error'

interface IFeedbackIcon {
  colorType: colorType
  feedbackType?: 'success' | 'error'
}

export const FeedbackIcon = ({ colorType, feedbackType }: IFeedbackIcon) => {
  const theme = useTheme()
  const mainColor = colorType === 'success' ? theme.colors.bluePrimary : theme.colors.red500

  if (feedbackType === 'success') {
    return (
      <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="white"
        />
        <path d="M30 15L17.5 27.5L10 20" stroke={mainColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14L11 5Z"
        fill={mainColor}
      />
      <circle cx="12" cy="19" r="1" fill={mainColor} />
    </svg>
  )
}
