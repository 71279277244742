import * as S from './style'

export const Header = () => {
  return (
    <S.HeaderContainer>
      <S.HeaderContent>
        <S.Icon src="/principia_logo_simbolo.svg" alt="Logo da Principia" />
        <S.Title>Principia</S.Title>
      </S.HeaderContent>
    </S.HeaderContainer>
  )
}
