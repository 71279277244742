import { useRouter } from 'next/router'
import { useContext } from 'react'
import { TemplateContext } from '~/contexts/TemplateContext'
import { useProgressBar } from '~/hooks/useProgressBar'

export const useMainPublic = () => {
  const { step } = useContext(TemplateContext)
  const { title } = useProgressBar()
  const router = useRouter()

  const hideProgressBar = Object.keys(title).includes(step) && router.pathname !== '/token/[publicToken]'

  return {
    hideProgressBar,
  }
}
