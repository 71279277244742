import * as S from './style'

interface IAddressProvi {
  companyAddress: string
  city: string
  cep: string
}

export const AddressProvi = ({ companyAddress, city, cep }: IAddressProvi) => {
  return (
    <S.DataProviContainer>
      <S.DescriptionText>{companyAddress}</S.DescriptionText>
      <S.DescriptionText>{city}</S.DescriptionText>
      <S.DescriptionText>{cep}</S.DescriptionText>
    </S.DataProviContainer>
  )
}
