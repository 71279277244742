import * as S from './style'

interface ILoadingInput {
  inputWidth?: string
  hasLabel?: boolean
  inputHeight?: string
}

export const LoadingInput = ({ inputWidth, inputHeight, hasLabel = true }: ILoadingInput) => {
  return (
    <S.InputLoadingContainer inputWidth={inputWidth} data-testid="input-loading">
      {hasLabel && <S.LoadingTitle />}
      <S.LoadingInput inputHeight={inputHeight} />
    </S.InputLoadingContainer>
  )
}
