import * as S from './style'

interface IDataProvi {
  companyName: string
  cnpj: string
}

export const DataProvi = ({ companyName, cnpj }: IDataProvi) => {
  return (
    <S.DataProviContainer>
      <S.DescriptionText>{companyName}</S.DescriptionText>
      <S.DescriptionText>{cnpj}</S.DescriptionText>
    </S.DataProviContainer>
  )
}
