import { LoadingInput } from '~/components/molecules/LoadingInput'
import { colorType, FeedbackIcon } from '../FeedbackIcon'
import { ProviIcon } from '../ProviIcon'
import * as S from './styles'

export interface IStatusHeader {
  title: string | null
  type: colorType
  showLogoOnDesktop?: boolean
  showIcon?: boolean
}

export const StatusHeader = ({ title, type, showLogoOnDesktop, showIcon = true }: IStatusHeader) => {
  return (
    <S.StatusHeaderContainer type={type} showLogoOnDesktop={showLogoOnDesktop} showIcon={showIcon}>
      {type === 'success' && !showIcon && <ProviIcon hideOnDesktop={!showLogoOnDesktop} />}
      {type === 'success' && showIcon && <FeedbackIcon colorType={type} feedbackType={type} />}

      {type === 'error' && <FeedbackIcon colorType={type} />}
      <S.StatusHeaderContent>
        {!title ? <LoadingInput hasLabel={false} /> : <S.StatusHeaderTitle>{title}</S.StatusHeaderTitle>}
      </S.StatusHeaderContent>
    </S.StatusHeaderContainer>
  )
}
