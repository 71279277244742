import styled from 'styled-components'
import Image from 'next/image'

export const FooterContainer = styled.footer`
  margin: 0 28px;
  padding: 12px 0 19px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`

export const FooterContent = styled.div`
  max-width: 1224px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(0, 80px)) 1fr;
  border-top: 2px solid ${({ theme }) => theme.colors.bluePrimaryDark};
  padding-top: 5px;
  gap: 24px;

  > div {
    :nth-child(2) {
      grid-column: 4 / span 3;
    }

    :nth-child(3) {
      grid-column: 7 / span 3;
    }
  }
`

export const SvgContainer = styled.div`
  grid-column: 1 / span 3;
`

export const Svg = styled(Image)``
