import styled, { css } from 'styled-components'
import { H4 } from '~/styles/global'
import { colorType } from '../FeedbackIcon'

interface IStatusHeaderContainer {
  type: colorType
  showLogoOnDesktop?: boolean
  showIcon?: boolean
}

export const StatusHeaderContainer = styled.div<IStatusHeaderContainer>`
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 0 0;

  padding: ${({ showLogoOnDesktop, type }) => (type === 'success' && showLogoOnDesktop ? '24px' : '104px 24px 13px')};
  gap: ${({ showLogoOnDesktop, type }) => (type === 'success' && showLogoOnDesktop ? '90px' : '11px')};
  background-color: ${({ type, theme }) => (type === 'success' ? theme.colors.bluePrimary : theme.colors.red500)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 180px;
    border-radius: 0;
    gap: 0;
    justify-content: space-between;
    padding: 24px;

    ${({ showIcon }) =>
      showIcon &&
      css`
        gap: 0;
        justify-content: flex-end;
      `};
  }
`

export const StatusHeaderContent = styled.div``

export const StatusHeaderTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.white100};
`
