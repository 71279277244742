import { AddressProvi } from '~/components/molecules/AddressProvi'
import { ContactProvi } from '~/components/molecules/ContactProvi'
import { DataProvi } from '~/components/molecules/DataProvi'

import * as S from './styles'

export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <S.FooterContainer>
      <S.FooterContent>
        <S.SvgContainer>
          <S.Svg src="/logo-principiapay.png" alt="logo PrincipiaPay" width={125} height={24} loading="lazy" />
        </S.SvgContainer>
        <DataProvi
          companyName={`© ${currentYear} PrincipiaPay Educação Tecnologia e Serviços Ltda`}
          cnpj="CNPJ 32.390.384/0001-92"
        />
        <AddressProvi companyAddress="Av. Dr. Cardoso de Melo, 1340 cj. 11" city="Vila Olímpia, São Paulo/SP" cep="04548-004" />
        <ContactProvi />
      </S.FooterContent>
    </S.FooterContainer>
  )
}
