import { useContext } from 'react'
import { ProgressBar as ProgressBarComponent } from '@provi/provi-components'
import { BackButton } from '~/components/atoms/BackButton'
import { TemplateContext } from '~/contexts/TemplateContext'
import { useProgressBar } from '~/hooks/useProgressBar'
import { defaultTheme } from '~/styles/theme'
import * as S from './style'

export const ProgressBar = () => {
  const { isLoadingData, isSendingData, step } = useContext(TemplateContext)
  const showMarginBottomVariant = step === 'financingSummary'
  const { getInfosFromStep } = useProgressBar()

  const { title, showProgressBar, previousScreenUniqueName, arrowDisabled } = getInfosFromStep()

  return (
    <S.ProgressBarWrapper isMarginBottomVariant={showMarginBottomVariant}>
      <BackButton step={previousScreenUniqueName} disabled={isLoadingData || isSendingData || arrowDisabled} />
      <S.ProgressBarTitle>{title}</S.ProgressBarTitle>
      <ProgressBarComponent
        progress={showProgressBar}
        backgroundColor={defaultTheme.colors.bluePrimaryLight}
        color={defaultTheme.colors.bluePrimary}
      />
    </S.ProgressBarWrapper>
  )
}
