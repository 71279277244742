import { useContext } from 'react'
import { IStep } from '~/@types/IStep'
import { TemplateContext } from '~/contexts/TemplateContext'
import * as S from './style'

interface IBackButton {
  step: IStep
  disabled?: boolean
}

export const BackButton = ({ step, disabled }: IBackButton) => {
  const { setStep } = useContext(TemplateContext)

  const handleClickArrow = (newStep: IStep) => {
    setStep(newStep)
  }

  return (
    <S.BackButtonContainer
      onClick={() => (disabled ? null : handleClickArrow(step))}
      disabled={disabled}
      aria-disabled={disabled}
      title={disabled ? 'Você não pode voltar mais' : 'Voltar'}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21 12.5L3.75 12.5M10 19.5L3 12.5L10 5"
          stroke={disabled ? '#CFCFCF' : 'black'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </S.BackButtonContainer>
  )
}
