import * as S from './style'

export const ContactProvi = () => {
  return (
    <S.ContactContainer>
      <S.RowContent>
        <S.SvgContainer>
          <S.Svg src="/whatsappLogo.svg" alt="ícone do whatsapp" width={20} height={20} />
        </S.SvgContainer>

        <S.LinkContainer href="https://api.whatsapp.com/send?phone=5511957712414&text=(Avalista) Oi, Principia! Pode me ajudar?">
          <S.SubLink
            href="https://api.whatsapp.com/send?phone=5511957712414&text=(Avalista) Oi, Principia! Pode me ajudar?"
            target="_blank"
            rel="noopener noreferrer"
          >
            +55 (11) 95771-2414
          </S.SubLink>
        </S.LinkContainer>
      </S.RowContent>

      <S.RowContent>
        <S.SvgContainer>
          <S.Svg src="/envelopeSimple.svg" alt="ícone de um envelope" width={20} height={20} />
        </S.SvgContainer>

        <S.LinkContainer href="mailto:papo@provi.com.br">
          <S.SubLink href="mailto:papo@provi.com.br" target="_blank" rel="noopener noreferrer">
            papo@provi.com.br
          </S.SubLink>
        </S.LinkContainer>
      </S.RowContent>

      <S.LinkContainer href="https://provi.com.br/termos-de-uso">
        <S.SubLink href="https://provi.com.br/termos-de-uso" target="_blank" rel="noopener noreferrer" isVariant>
          Termos de uso e política de privacidade
        </S.SubLink>
      </S.LinkContainer>
    </S.ContactContainer>
  )
}
