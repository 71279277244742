function isEmptyString(string: string) {
  return string.trim() === ''
}
function isEmpty(string: string) {
  return string.trim().length === 0
}
function isEmptyArray(array: string[]) {
  return array.length === 0
}
function hasNumbers(string: string) {
  return string.split('').filter((item) => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(parseInt(item))).length
}

export function getFirstName(fullName: string) {
  return fullName ? fullName.split(' ')[0] : ''
}

export function getLastName(fullName: string) {
  if (!fullName) {
    return ''
  }
  return fullName
    .trim()
    .split(' ')
    .filter((_name, index) => index > 0)
}

export const validateFullName = (fullName: string) => {
  fullName = fullName.trim()
  fullName = fullName.replace(/\s\s+/g, ' ')

  let result = true
  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ\-' ]+$/
  if (!REGEX.test(fullName)) {
    result = false
    return result
  }
  const firstName = getFirstName(fullName)
  const lastName = getLastName(fullName)

  if (
    firstName.length < 2 ||
    (lastName[lastName.length - 1] && lastName[lastName.length - 1].length > 0 && lastName[lastName.length - 1].length < 2)
  ) {
    return false
  }

  if (isEmpty(firstName) || isEmptyString(firstName) || (Array.isArray(lastName) && isEmptyArray(lastName))) {
    result = false
    return result
  }

  Array.isArray(lastName) &&
    lastName.forEach((name: string) => (isEmpty(name) || isEmptyString(name) || hasNumbers(name) ? (result = false) : null))
  return result
}
