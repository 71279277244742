import styled, { css } from 'styled-components'
import { Overline } from '~/styles/global'

export const ProgressBarWrapper = styled.div<{ isMarginBottomVariant: boolean }>`
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin: 10px 24px 35px;

  ${({ isMarginBottomVariant }) =>
    isMarginBottomVariant &&
    css`
      margin-bottom: 16px;
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 14px;
  }
`

export const ProgressBarTitle = styled(Overline)``
