export const isEmailValid = (information: string) => {
  const email = information?.trim()
  if (!email) return false

  const emailString = String(email)
  const emailLowerCase = emailString.toLowerCase()

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(emailLowerCase)
}
