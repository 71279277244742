import { FC } from 'react'
import { MainHead } from './MainHead'
import { useMainPublic } from './hooks'
import { ProgressBar } from '../ProgressBar'
import { Footer } from '~/components/organisms/Footer'
import { Header } from '~/components/organisms/Header'
import * as S from './styles'

export const MainPublic: FC = ({ children }) => {
  const { hideProgressBar } = useMainPublic()

  return (
    <>
      <MainHead title="Início" />
      <S.MainContainer>
        <Header />
        <S.MainContent>
          <S.MainMain>
            {hideProgressBar && <ProgressBar />}
            {children}
          </S.MainMain>
        </S.MainContent>
        <Footer />
      </S.MainContainer>
    </>
  )
}
