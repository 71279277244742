import styled, { css } from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 10 / span 4;

  a:nth-child(1),
  a:nth-child(2) {
    margin-left: 4px;
  }

  div + div {
    margin: 4px 0;
  }
`

export const RowContent = styled.div`
  display: flex;
  align-items: center;
`

export const LinkContainer = styled(Link)``

export const SubLink = styled.a<{ isVariant?: boolean }>`
  color: #000000;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-decoration-line: none;

  ${({ isVariant }) =>
    isVariant &&
    css`
      text-decoration-line: underline;
    `}
`

export const SvgContainer = styled.div`
  grid-column: 1 / span 3;
`

export const Svg = styled(Image)``
